<template>
    <div class="awards-detail-table-wrapper">
        <div class="title">{{ title }}</div>
        <div v-for="(i, idx) in info" class="info" :key="idx">
            <span class="info-title">{{ i.title }}</span>
            <span class="info-value">：{{ i.value }}</span>
        </div>
        <Table
            ref="awardsDetailTable"
            class="awardsDetailTable"
            :class="{ 'no-sum-row': !hasTotal }"
            :rows="tableShowData"
            :columnConfig="tableColumnConfig()"
            :isFocusRow="true"
            :isLoading="isLoading"
        >
            <template #insNo="{ isHeader, row }">
                <router-link
                    v-if="!isHeader && !row.className"
                    :to="{
                        name: 'TiaInsuranceDetail',
                        params: { id: 14782757, manCode: 100111913 }
                    }"
                    target="_blank"
                >
                    {{ row.insNo }}
                </router-link>
            </template>
            <template #level="{ isHeader, row }">
                <div
                    v-if="!isHeader && !row.className"
                    class="link"
                    @click="
                        queryOnAwardsPage({
                            contestType: 'internal',
                            role: 'supervisor',
                            contestId
                        })
                    "
                >
                    {{ row.level }}
                </div>
            </template>
        </Table>
        <div
            v-if="dataList.length > (isMobile ? 3 : 5) && !isLoading"
            :class="isExpand ? 'shrink' : 'expand'"
            @click="isExpand = !isExpand"
        >
            {{ isExpand ? '收合' : '更多' }}
            <div
                :class="
                    isExpand
                        ? 'toggle-content-icon_expanded'
                        : 'toggle-content-icon'
                "
            />
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue'
import { awardsDetailColumn } from '@/assets/javascripts/award/setting'
import _ from 'lodash'

export default {
    name: 'AwardsDetailTable',
    components: { Table },
    props: {
        title: {
            type: String,
            default: ''
        },
        info: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        dataList: {
            type: Array,
            default: function () {
                return []
            }
        },
        config: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterValue: {
            type: String,
            default: ''
        }
    },
    methods: {
        tableColumnConfig() {
            let column = _.cloneDeep(awardsDetailColumn)
            let tableConfig = _.pick(column, this.config)
            this.hasTotal = Object.keys(tableConfig).some(
                (key) => column[key].total === 'sum'
            )
            if (this.dataList.length > 1) {
                tableConfig[this.config[1]].total = 'key'
            } else {
                delete tableConfig.serialNumber
            }
            return tableConfig
        },
        transformData(rows) {
            return rows.map((row, idx) => {
                let r = _.cloneDeep(row)
                r.serialNumber = idx + 1
                return r
            })
        },
        collapseHandler() {
            if (!this.dataList.length > 0) return
            this.$nextTick(() => {
                const table = this.$refs.awardsDetailTable.$el
                const tableBody = table.querySelector('.table-body')
                const rows = tableBody.querySelectorAll('.row-container')

                const dataCount = this.dataList.length + 1

                if (this.isMobile) {
                    if (this.isExpand) {
                        rows.forEach((row) => row.classList.remove('hide'))
                    } else {
                        for (let i = rows.length - 1; i >= this.minRow; i--) {
                            rows[i].classList.add('hide')
                        }
                    }
                } else {
                    const rowHeight = 50
                    const rowsHeight = this.isExpand
                        ? `${dataCount * rowHeight}px`
                        : `${this.minRow * rowHeight}px`
                    tableBody.style.setProperty('--body-height', rowsHeight)
                }
            })
        },
        updateIsMobile() {
            this.isMobile = this.$isMobile()
        },
        queryOnAwardsPage(payload) {
            let p = _.cloneDeep(payload)
            // TODO: 依 codes 更新 #contestName#contestId
            if (p.contestName === 'hga') {
                p.contestId = 1
            } else if (p.contestName === 'lga') {
                p.contestId = 2
            }
            this.$crossPageFilter('Award', p, {}, true)
        }
    },
    computed: {
        tableShowData() {
            let dataset = this.dataList
            dataset = this.transformData(dataset)
            return dataset
        },
        minRow() {
            const sumRow = this.dataList.length <= 1 ? 0 : 1
            const limitRow = this.isMobile ? 3 : 5
            return sumRow + Math.min(limitRow, this.dataList.length)
        },
        contestId() {
            if (this.filterValue.includes('hga')) {
                return 1
            } else if (this.filterValue.includes('lga')) {
                return 2
            } else {
                return 0
            }
        }
    },
    watch: {
        isExpand() {
            if (this.isMobile && this.isExpand) {
                this.mobileExpanding.state = true
                this.mobileExpanding.scrollY = window.scrollY
            }
            this.collapseHandler()
        },
        dataList() {
            this.collapseHandler()
        },
        isMobile() {
            if (!this.isMobile) {
                document
                    .querySelectorAll('.row-container')
                    .forEach((row) => row.classList.remove('hide'))
            }
            this.collapseHandler()
        },
        filterValue() {
            this.isExpand = false
        }
    },
    data() {
        return {
            isExpand: false,
            isMobile: false,
            hasTotal: false,
            mobileExpanding: { state: false, scrollY: 0 }
        }
    },
    mounted() {
        this.collapseHandler()
        this.updateIsMobile()
        window.addEventListener('resize', this.updateIsMobile)
    },
    unmounted() {
        window.removeEventListener('resize', this.updateIsMobile)
    },
    updated() {
        if (this.mobileExpanding?.state) {
            this.$nextTick(() => {
                window.scrollTo(0, this.mobileExpanding.scrollY)
                this.mobileExpanding.state = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.title {
    font-size: 18px;
    color: $sixth-black;
}

.info {
    font-size: 14px;
    margin: 10px 0;
    .info-title {
        color: $placeholder-black;
    }
    .info-value {
        color: $sixth-black;
    }
}

.awardsDetailTable {
    :deep(.table-container) {
        .table-body {
            height: var(--body-height);
            transition: 0.5s;
            overflow: hidden;

            .row-container {
                a,
                .link {
                    color: $fourth-blue !important;
                    cursor: pointer;
                }
            }

            @media (max-width: 576px) {
                height: auto;

                .row-container {
                    .sum-row {
                        .ins-no,
                        .supplier,
                        .payer,
                        .receive-date,
                        .effect-date,
                        .register-date,
                        .bg-name,
                        .sales-name,
                        .org-bm-name,
                        .title,
                        .level,
                        .recommend-person,
                        .recommended-person,
                        .recommended-person-title {
                            display: none;
                        }
                    }

                    .row {
                        .serial-number {
                            justify-content: flex-start;
                            &:before {
                                display: none;
                            }
                            &:after {
                                content: '.';
                            }
                        }
                    }
                }
            }
        }

        .serial-number {
            overflow: hidden;
        }
    }

    &.no-sum-row {
        :deep(.table-container) {
            @media (max-width: 576px) {
                .table-body {
                    .row-container .sum-row {
                        display: none;
                    }
                }
            }
        }
    }
}

.expand,
.shrink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
}

.expand {
    color: $fourth-blue;
    .toggle-content-icon {
        margin-left: 5px;
        height: 10px;
        width: 10px;
        transform: rotate(90deg);
    }
}

.shrink {
    color: $eighth-grey;
    .toggle-content-icon_expanded {
        margin-left: 5px;
        height: 10px;
        width: 10px;
        transform: rotate(180deg);
    }
}
</style>
